import { FALLBACK_LOCALE, LANG_TO_LOCALE_SNAKE, LOCALE_TO_LANG } from '@/constants/language'
import { LanguageCode, type TranslatedFields, type TranslationFields } from '@/interfaces/Language.interfaces'

export const langToLocale = (lang: string) => {
  const language = lang.substring(0, 2)

  if (language in LANG_TO_LOCALE_SNAKE) {
    return LANG_TO_LOCALE_SNAKE[language]
  }

  return FALLBACK_LOCALE
}

export const getNavigatorLocale = () => langToLocale(window.navigator.language)

export const localeToLang = (locale: keyof typeof LOCALE_TO_LANG) => {
  if (locale in LOCALE_TO_LANG) {
    return LOCALE_TO_LANG[locale]
  }

  return LOCALE_TO_LANG[FALLBACK_LOCALE]
}

export const isLanguageFrench = (): boolean => {
  const userLanguage = window.navigator.language
  return userLanguage === 'fr' || userLanguage.startsWith('fr-')
}

export const getTranslatedValue = (lang: LanguageCode, fields: TranslatedFields): string => {
  const translatedValue = fields[lang]
  if (translatedValue) {
    return translatedValue
  }
  return fields.en ?? fields.fr ?? ''
}

export const languageTranslateName = (lang: string, nameEn: string, nameFr: string): string => {
  if (lang === 'fr') {
    return nameFr
  }
  return nameEn
}

export const languageTranslateFieldName = (lang: string, obj: object & Partial<TranslationFields>): string => {
  const name = 'name_' + lang

  if (typeof obj[name] === 'string' && obj[name] !== '') {
    return obj[name] ?? ''
  }

  if (lang === 'en') {
    return '⚠️ ' + obj['name_fr']
  }

  return '⚠️ ' + obj['name_en']
}

export const languageTranslateField = (
  lang: string,
  obj: object & Record<string, string>,
  attr: string
): string | undefined => {
  if (lang !== '') {
    const name = attr + '_' + lang

    if (Object.hasOwn(obj, name) && obj[name]) {
      return obj[name]
    }
  }

  const attrFr = `${attr}_fr`
  const attrEn = `${attr}_en`

  if (Object.hasOwn(obj, attrFr) && obj[attrFr]) {
    return obj[attrFr]
  } else if (Object.hasOwn(obj, attrEn) && obj[attrEn]) {
    return obj[attrEn]
  }

  return obj[attr]
}

export const isUntranslated = (str?: string): string => {
  if (!str) {
    return ''
  }

  if (str.includes(' ')) {
    return 'untranslated-en'
  }

  if (str.includes(' ')) {
    return 'untranslated-fr'
  }

  return ''
}

export const isUntranslatedIt = (str?: string): string => {
  if (!str) {
    return ''
  }

  if (str.includes(' ') || str.includes(' ')) {
    return 'font-italic'
  }

  return ''
}

export const isTranslatedFields = (fields: unknown): fields is TranslatedFields =>
  typeof fields === 'object' && fields !== null && 'en' in fields && 'fr' in fields
